import { formatDateOnRead } from "../../time/formatTimezoneOffset";
import { SecondsToDate } from "../../time/secondsToDate";
import { checkUndefinedOrNull } from "./checkUndefined";

const UNDEFINED = "undefined";

export default function checkSendRecord(recordSnap, orgTimezone) {
  const record = recordSnap.data();

  console.log(record);

  const emailEvents = () => {
    if (typeof record.emailEvents !== UNDEFINED) {
        const emailEvents = record.emailEvents;
        const newRecords = []
        emailEvents.forEach((eEvent) => {
            let epochTimestampInSeconds = eEvent.timestamp;
            // Subtract one hour from timestamp to account for timezone offset
            epochTimestampInSeconds = epochTimestampInSeconds - 3600;
            const epochTimestampInMilliseconds = epochTimestampInSeconds * 1000;
            const date = new Date(epochTimestampInMilliseconds);
            const formattedDate = formatDateOnRead({ date, orgTimezone })
            const eventCount = eEvent.count;
            const newEvent = {
                email: checkUndefinedOrNull(eEvent.email, ''),
                event: checkUndefinedOrNull(eEvent.event, ''),
                timestamp: formattedDate.toDate(),
                count: checkUndefinedOrNull(eventCount, 1),
                serverDetails: checkUndefinedOrNull(eEvent.serverDetails, ''),
                serverDescription: checkUndefinedOrNull(eEvent.serverDescription, ''),
            }
            newRecords.push(newEvent)
        })

        const deliveredIndex = newRecords.findIndex(item => item.event === "delivered");

        if (deliveredIndex !== -1) {
          const targetObject = newRecords.splice(deliveredIndex, 1)[0];
          newRecords.unshift(targetObject);
        }
        const processedIndex = newRecords.findIndex(item => item.event === "processed");

        if (processedIndex !== -1) {
          const targetObject = newRecords.splice(processedIndex, 1)[0];
          newRecords.unshift(targetObject);
        }
        
        return newRecords;
    }
    return [];
  };

  const createdOn = () => {
    const recordCreatedOn = new Date(record.createdOn.toDate())
    const formattedCreatedOn = formatDateOnRead({ recordCreatedOn, orgTimezone });
    return checkUndefinedOrNull(formattedCreatedOn, null);
  }
  
  const sendRecord = {
    createdOn: record.createdOn.toDate(),
    emailEvents: emailEvents(),
    attachments: record.attachments || [],
    createdBy: record.createdBy || '',
    fromTemplate: record.fromTemplate || false,
    id: record.id || '',
    paymentAmt: record.paymentAmt || 0,
    postmarkId: record.postmarkId || '',
    sendPaymentLink: record.sendPaymentLink || false,
    status: record.status || '',
  }

  return sendRecord;
}
